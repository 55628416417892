import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useAuth } from "context/AuthContext";
import useSWR from "swr";

import { useToast } from "./useToast";
import { getSessionCompanies } from "utils";

const MIX_API =
  process.env.REACT_APP_MIX_API ??
  "https://api.interno.mixfiscal.com.br/interno/v1/";

export const useMixApi1 = () => {
  const onRejected = error => {
    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: MIX_API,
    }),
  );

  apiRef.current.interceptors.request.use(config => {
    config.headers.Authorization = getSessionCompanies();
    return config;
  });

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useFetchMixAPi1 = (url, axiosConfigs, config) => {
  const api = useMixApi1();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);
      return response.data;
    },
    config,
  );

  // Revalida todas as requisicoes para carregar os dados corretamente!
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
      setRevalidateAll();
    }
  }, [tokenCompanies, revalidate, revalidateAll, setRevalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useMixApi1Toast = apiRef => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const api = useMixApi1();
  const { addToast } = useToast();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
    redirect = false,
    urlRedirect = "",
    fieldGetId = "",
    isDisableMsg = false,
  }) => {
    try {
      setIsLoading(true);
      const { data } = await (apiRef ?? api)({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      if (!isDisableMsg) {
        addToast({
          type: "success",
          title: "SUCESSO",
          description: messageSuccess,
        });
      }

      setData(data);
      setIsLoading(false);
      if (
        redirect &&
        urlRedirect !== "" &&
        fieldGetId !== "" &&
        data?.success
      ) {
        const field = data?.data[fieldGetId];
        window.location.href = `${urlRedirect}/${field}`;
      }
      return data;
    } catch (err) {
      setData(err);
      if (!isDisableMsg) {
        addToast({
          type: "error",
          title: "FALHA",
          description: messageFail,
        });
      }
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    onSend,
  };
};
