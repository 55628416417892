import { createSlice } from "@reduxjs/toolkit";

export const initialCest = {
  params: {
    page: 1,
    limit: 10,
  },
  relate: false,
};

const initialState = () => initialCest;

export const CestSlice = createSlice({
  name: "legisCest",
  initialState,
  reducers: {
    handleRelate: (state, { payload }) => {
      state.relate = payload;
      return state;
    },
  },
});

export const { handleRelate } = CestSlice.actions;

export default CestSlice.reducer;
